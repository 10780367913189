<template>
  <div>
    <!-- BREADCRUMB -->
    <section
      class="py-4 breadcrumb-bg"
      style="
        background-image: url(../assets/img/background/page-title-bg-img6.jpg);
      "
    >
      <div class="container">
        <div class="breadcrumb-holder wow fadeInUp">
          <div>
            <h1 class="breadcrumb-title">活動實錄</h1>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">Home</a>
              </li>
              <li class="breadcrumb-item">
                <router-link class="text-white" to="/activity"
                  >活動實錄</router-link
                >
              </li>
              <li class="breadcrumb-item">
                <router-link class="text-white" :to="`/activity/${category}`">{{
                  record.category
                }}</router-link>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                活動內容
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- content -->
    <section class="py-8 py-md-10">
      <div class="container">
        <div class="card">
          <div class="position-relative">
            <img
              class="card-img-top"
              :src="record.banner"
              alt="Card image cap"
            />
            <div class="card-img-overlay">
              <span class="badge badge-rounded badge-warning">
                {{ record.month }} / {{ record.date }}
              </span>
            </div>
          </div>
          <div
            class="card-body border-top-5 px-3 rounded-bottom border-warning"
          >
            <h3 class="card-title text-warning mb-5">{{ record.title }}</h3>
            <ul class="list-unstyled d-flex mb-1">
              <li class="mr-4 text-muted">
                <i class="fa fa-map-marker mr-2" aria-hidden="true"></i
                >{{ record.place }}
              </li>
              <li class="mr-2 text-muted">
                <i class="fa fa-camera-retro mr-2" aria-hidden="true"></i
                >{{ record.img_count }} Pics
              </li>
            </ul>
            <p class="card-text text-justify mb-6" v-html="record.content"></p>
          </div>
        </div>
        <section class="py-4 py-md-6">
          <div class="container">
            <div class="section-title justify-content-center mb-4 mb-md-8">
              <span class="shape shape-left bg-info"></span>
              <h2 class="text-danger">活動花絮</h2>
              <span class="shape shape-right bg-info"></span>
            </div>
            <div id="gallery-grid">
              <div>
                <isotope
                  class="row grid"
                  ref="cpt"
                  id="root_isotope1"
                >
                  <div
                    class="col-md-4 col-lg-3 col-xs-12 element-item"
                    :class="item.filterText"
                    v-for="item in record.img" :key="item.id"
                  >
                    <div class="media media-hoverable justify-content-center">
                      <div class="position-relative w-100">
                        <img class="media-img w-100 adjust-height" :data-src="item.img" :src="item.img" alt="gallery-img">
                        <a class="media-img-overlay" data-fancybox="gallery" :href="item.img">
                          <div class="btn btn-squre">
                            <i class="fa fa-search-plus"></i>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </isotope>
              </div>
            </div>
            <!-- <div id="gallery-grid">
              <div class="row grid">
                <div class="col-md-4 col-lg-3 col-xs-12 element-item camp" v-for="item in record.img" :key="item.id">
                  <div class="media media-hoverable justify-content-center">
                    <div class="position-relative w-100">
                      <img class="media-img w-100 adjust-height" :data-src="item.img" :src="item.img"
                      alt="gallery-img">
                      <a class="media-img-overlay" data-fancybox="gallery" :href="item.img">
                        <div class="btn btn-squre">
                          <i class="fa fa-search-plus"></i>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import tokenGenerator from '@/tokenGenerator.js'
import isotope from 'vueisotope'
export default {
  name: 'record',
  components: {
    isotope
  },
  data () {
    return {
      record: {},
      category: '',
      dataMap: {
        營隊: 'camp',
        課程: 'course',
        企業講座: 'lecture',
        公益團體: 'welfare'
      }
    }
  },
  created () {
    const vm = this
    vm.getData()
  },
  mounted () {
    require('@/assets/js/base.js')
    // 修正活動花絮跑版問題
    setTimeout(() => {
      const rootIsotope1 = document.querySelector('#root_isotope1')
      rootIsotope1.style.height = 100 + '%'
    }, 1000)
  },
  methods: {
    getData () {
      const vm = this
      const id = vm.$route.params.id
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/event/detail`

      vm.axios
        .post(api, {
          token: tokenGenerator(),
          id: id
        })
        .then((res) => {
          vm.record = res.data.content
          vm.category = vm.dataMap[vm.record.category]
          const [yymmdd] = vm.record.eventDate.split(' ')
          const [yy, mm, dd] = yymmdd.split('-')
          vm.$set(vm.record, 'year', yy)
          vm.$set(vm.record, 'month', mm)
          vm.$set(vm.record, 'date', dd)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.adjust-height {
  height: 207px;
  object-fit: cover;
}
</style>
